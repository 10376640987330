import React from 'react';
import { useSpring, animated, config } from 'react-spring';

const FadeUp = ({ children }) => {
  const { x, y } = useSpring({
    from: {
      x: '0',
      y: '20'
    },
    to: {
      x: '1',
      y: '0'
    },
    config: config.molasses
  });
  return (
    <animated.div
      style={{
        opacity: x.interpolate(x => x),
        transform: y.interpolate(y => `translate3d(0,${y}%, 0)`)
      }}
    >
      {children}
    </animated.div>
  );
};

export default FadeUp;
