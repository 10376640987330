import React from 'react';
import JournalEntry from './JournalEntry';

import '../css/journal-feed.css';

const JournalFeed = ({ journals }) => (
  <div className="w-100 pl4 pr4 pl5-l pr5-l mw9 center mt4 mb4 mt6-l mb6-l">
    {journals.map(item => (
      <JournalEntry item={item.node.data} />
    ))}
  </div>
);

export default JournalFeed;
