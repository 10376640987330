import React from 'react';
import WayFadeUp from './transitions/WayFadeUp';
import PrismicLink from '../components/PrismicLink';

import { PrismicImage } from './common/prismicUtils';

const JournalEntry = ({ item }) => {
  const { image, text, title, link } = item;
  return (
    <WayFadeUp>
      <div className='w-100 flex-l pl0 pr0 pl5-l pr5-l items-center justify-between mt5 mb5 journal-entry mt6-l mb6-l'>
        <div
          className={`w-100 w-40-l ${
            image?.fluid?.aspectRatio < 1 ? 'portrait' : 'landscape'
          }`}
        >
          <PrismicImage {...image.fluid} className='center-l' />
        </div>
        <div className='w-100 w-50-l'>
          <h2 className='tiempos f3 mb3'>{title.text}</h2>
          <div
            className='f4 measure lh-copy'
            dangerouslySetInnerHTML={{ __html: text.html }}
          />
          {link.url && (
            <PrismicLink
              {...link}
              activeClassName='active'
              className='link grey f4'
            >
              Read More
            </PrismicLink>
          )}
        </div>
      </div>
    </WayFadeUp>
  );
};

export default JournalEntry;
