import React from 'react';
import {Link as GatsbyLink} from 'gatsby';

const PrismicLink = ({document, slug, url, children, target, link_type, activeClassName, ...other}) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  // Use Gatsby Link for internal links, and <a> for others
  if (link_type === 'Document') {
    switch (document[0].type) {
      case 'project':
        return (
          <GatsbyLink to={`projects/${url}`} activeClassName={activeClassName} {...other}>
            {children}
          </GatsbyLink>
        );
      case 'home_page':
        return (
          <GatsbyLink to="/" activeClassName={activeClassName} {...other}>
            {children}
          </GatsbyLink>
        );
      default:
        return (
          <GatsbyLink to={url} activeClassName={activeClassName} {...other}>
            {children}
          </GatsbyLink>
        );
    }
  }
  return (
    <a href={url} {...other} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  );
};

export default PrismicLink;
